import axios from 'axios';

const chunk = (arr, n) => arr.length ? [arr.slice(0, n), ...chunk(arr.slice(n), n)] : []

export const lookUpTokenPrices = async function (id_array) {
    const prices = {}
    for (const id_chunk of chunk(id_array, 50)) {
        let ids = id_chunk.join('%2C')
        let res = (await axios.get('https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=' + ids + '&vs_currencies=usd')).data

        for (const [key, v] of Object.entries(res)) {
            if (v.usd) prices[key] = v;
        }
    }
    return prices
}

const formatter = new Intl.NumberFormat({ style: 'currency', maximumFractionDigits: 2 })
export const formatMoney = (amount) => {
    return formatter.format(amount)
}