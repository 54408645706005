
import { Contract, Provider } from 'ethcall';
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../shared/context/AppContext";
import { CURVE_ABI, ERC20_ABI, JAR_ABI, MINTER_ABI, UNI_ABI } from '../../utils/abi';
import { formatMoney, lookUpTokenPrices } from '../../utils/prices';
import './index.css';

const { ethers } = require('ethers');


export function ChefPool(props) {

    const { wallet, address } = useContext(AppContext)

    const [poolData, setPoolData] = useState([])
    const [poolCount, setPoolCount] = useState(0)

    const { chef, chefAddress, chefAbi, rewardTokenTicker,
        rewardTokenFunction, rewardsPerBlockFunction, rewardsPerWeekFixed, pendingRewardsFunction,
        extraPrices, deathPoolIndices, showAll } = props.parameters
    const { project } = props

    useEffect(() => {
        const loadData = async () => {
            setPoolData([])

            const provider = new ethers.providers.Web3Provider(wallet.provider)
            const root = {
                provider,
                ethcallProvider: new Provider()
            }
            await root.ethcallProvider.init(provider)

            const chefContract = chef ?? new ethers.Contract(chefAddress, chefAbi, provider);

            const poolCount = parseInt(await chefContract.poolLength(), 10);
            const totalAllocPoints = await chefContract.totalAllocPoint();
            setPoolCount(poolCount)

            var tokens = {};

            const rewardTokenAddress = await chefContract.callStatic[rewardTokenFunction]();
            const rewardToken = await getToken(root, rewardTokenAddress, chefAddress, address);
            const rewardsPerWeek = rewardsPerWeekFixed ??
                await chefContract.callStatic[rewardsPerBlockFunction]()
                / 10 ** rewardToken.decimals * 604800 / 13.5

            const poolInfos = await Promise.all([...Array(poolCount).keys()].map(async (x) => {
                try {
                    return await getPoolInfo(root, address, chefContract, chefAddress, x, pendingRewardsFunction, showAll);
                }
                catch (ex) {
                    console.log(`Error loading pool ${x}: ${ex}`);
                    return null;
                }
            }));

            var tokenAddresses = [].concat.apply([], poolInfos.filter(x => x?.poolToken).map(x => x.poolToken.tokens));
            var prices = await lookUpTokenPrices(tokenAddresses);
            if (extraPrices) {
                for (const [k, v] of Object.entries(extraPrices)) {
                    if (v.usd) {
                        prices[k] = v
                    }
                }
            }
            //prices["0x194ebd173f6cdace046c53eacce9b953f28411d1"] = { usd : 1.22 } //"temporary" solution

            console.log(prices)

            await Promise.all(tokenAddresses.map(async (_address) => {
                tokens[_address] = await getToken(root, _address, chefAddress, address);
            }));

            if (deathPoolIndices) {   //load prices for the deathpool assets
                deathPoolIndices.map(i => poolInfos[i])
                    .map(poolInfo =>
                        poolInfo.poolToken ? getPoolPrices(tokens, prices, poolInfo.poolToken, "eth") : undefined);
            }

            const poolPrices = poolInfos.map(poolInfo => poolInfo?.poolToken ? getPoolPrices(tokens, prices, poolInfo.poolToken) : undefined);

            const chain = "eth"

            let aprs = []
            let poolData = []
            for (let i = 0; i < poolCount; i++) {
                if (poolPrices[i]) {

                    const poolInfo = poolInfos[i]

                    const sp = (poolInfo.stakedToken == null) ? null : getPoolPrices(tokens, prices, poolInfo.stakedToken, chain);
                    const poolRewardsPerWeek = poolInfo.allocPoints / totalAllocPoints * rewardsPerWeek;
                    if (poolRewardsPerWeek === 0 && rewardsPerWeek !== 0) return;
                    const userStaked = poolInfo.userLPStaked ?? poolInfo.userStaked;
                    const rewardPrice = getParameterCaseInsensitive(prices, rewardTokenAddress)?.usd;
                    const staked_tvl = sp?.staked_tvl ?? poolPrices[i].staked_tvl;

                    const usdPerWeek = rewardPrice * poolRewardsPerWeek
                    const weeklyAPR = usdPerWeek / staked_tvl * 100
                    const dailyAPR = weeklyAPR / 7
                    const yearlyAPR = weeklyAPR * 52

                    const apr = {
                        'daily': dailyAPR,
                        'weekly': weeklyAPR,
                        'yearly': yearlyAPR
                    }

                    const userStakedUsd = userStaked * poolPrices[i].price
                    const userStakedPct = userStakedUsd / staked_tvl * 100
                    const userWeeklyRewards = userStakedPct * poolRewardsPerWeek / 100;
                    const userDailyRewards = userWeeklyRewards / 7;
                    const userYearlyRewards = userWeeklyRewards * 52;

                    const rewards = {
                        'daily': userDailyRewards,
                        'weekly': userWeeklyRewards,
                        'yearly': userYearlyRewards
                    }

                    poolData.push({
                        poolPrice: poolPrices[i],
                        poolInfo,
                        userStaked, rewardPrice, staked_tvl, apr,
                        poolRewardsPerWeek,
                        userStakedUsd,
                        userStakedPct,
                        rewards
                    })
                }
            }

            console.log(poolData)
            setPoolData(poolData)
        }

        loadData();

        /*
                let totalUserStaked = 0, totalStaked = 0, averageApr = 0;
                for (const a of aprs) {
                    if (a && !isNaN(a.totalStakedUsd)) {
                        totalStaked += a.totalStakedUsd;
                    }
                    if (a && a.userStakedUsd > 0) {
                        totalUserStaked += a.userStakedUsd;
                        averageApr += a.userStakedUsd * a.yearlyAPR / 100;
                        }
                }
                averageApr = averageApr / totalUserStaked;
                _print_bold(`Total Staked: $${formatMoney(totalStaked)}`);
                if (totalUserStaked > 0) {
                    _print_bold(`\nYou are staking a total of $${formatMoney(totalUserStaked)} at an average APR of ${(averageApr * 100).toFixed(2)}%`)
                    _print(`Estimated earnings:`
                        + ` Day $${formatMoney(totalUserStaked * averageApr / 365)}`
                        + ` Week $${formatMoney(totalUserStaked * averageApr / 52)}`
                        + ` Year $${formatMoney(totalUserStaked * averageApr)}\n`);
                } */
    }, [wallet, address, chef, chefAbi, chefAddress, deathPoolIndices,
        extraPrices, rewardTokenFunction, pendingRewardsFunction,
        rewardsPerBlockFunction, rewardsPerWeekFixed, showAll]);

    return <div style={{ width: "96%", margin: "0 1em 0 1em" }}>
        <h4>{project}, pool count: {poolCount}{poolData.length === 0 ? " — Loading…" : ""}</h4>
        <table>
            <tr>
                <td>Pool</td>
                <td>Total staked</td>
                <td>Price</td>
                <td>{rewardTokenTicker} Per Week</td>
                <td>APR</td>
                <td>You stake</td>
                <td>Est. reward ({rewardTokenTicker})</td>
            </tr>
            {poolData.map((p) =>
                <tr>
                    <td>
                        <b><a target="_nlank" href={`https://etherscan.io/address/${p.poolInfo.address}`}>
                            {p.poolPrice.stakeTokenTicker}</a></b>
                    </td>
                    <td>
                        {p.poolPrice.staked} {p.poolPrice.symbol}<br />
                        ${formatMoney(p.staked_tvl)}
                    </td>
                    <td>
                        ${formatMoney(p.poolPrice.price)}<br />
                    TVL: ${formatMoney(p.poolPrice.tvl)}
                    </td>
                    <td>
                        {p.poolRewardsPerWeek.toFixed(2)}<br />
                        ${formatMoney(p.poolRewardsPerWeek * p.rewardPrice)}
                    </td>
                    <td>
                        daily {p.apr.daily.toFixed(2)}%<br />
                    weekly {p.apr.weekly.toFixed(2)}%<br />
                    yearly {p.apr.yearly.toFixed(2)}%
                </td>
                    {(p.userStaked > 0) ?
                        <td>
                            {formatMoney(p.userStaked)} {p.poolPrice.stakeTokenTicker} <br />
                        ${formatMoney(p.userStakedUsd)}<br />
                            {p.userStakedPct.toFixed(2)}% of pool
                        </td> : <td></td>}
                    {(p.userStaked > 0) ?
                        <td>daily {p.rewards.daily.toFixed(2)}<br />
                        weekly {p.rewards.weekly.toFixed(2)}<br />
                        yearly {p.rewards.yearly.toFixed(2)}</td> : <td></td>}
                </tr>
            )}
        </table>
    </div>
}

async function getPoolInfo(app, address, chefContract, chefAddress, poolIndex, pendingRewardsFunction, showAll = false) {
    const poolInfo = await chefContract.poolInfo(poolIndex);
    if (poolInfo.allocPoint == 0 && !showAll) {
        return {
            address: poolInfo.lpToken,
            allocPoints: poolInfo.allocPoint ?? 1,
            poolToken: null,
            userStaked: 0,
            pendingRewardTokens: 0,
            stakedToken: null,
            userLPStaked: 0,
            lastRewardBlock: poolInfo.lastRewardBlock
        };
    }
    const poolToken = await getToken(app, poolInfo.lpToken ?? poolInfo.stakingToken, chefAddress, address);
    const userInfo = await chefContract.userInfo(poolIndex, address);
    const pendingRewardTokens = await chefContract.callStatic[pendingRewardsFunction](poolIndex, address);
    const staked = userInfo.amount / 10 ** poolToken.decimals;
    var stakedToken;
    var userLPStaked;
    if (poolInfo.stakedHoldableToken != null &&
        poolInfo.stakedHoldableToken != "0x0000000000000000000000000000000000000000") {
        stakedToken = await getToken(app, poolInfo.stakedHoldableToken, chefAddress, address);
        userLPStaked = userInfo.stakedLPAmount / 10 ** poolToken.decimals
    }
    return {
        address: poolInfo.lpToken ?? poolInfo.stakingToken,
        allocPoints: poolInfo.allocPoint ?? 1,
        poolToken: poolToken,
        userStaked: staked,
        pendingRewardTokens: pendingRewardTokens / 10 ** 18,
        stakedToken: stakedToken,
        userLPStaked: userLPStaked,
        lastRewardBlock: poolInfo.lastRewardBlock
    };
}


function getPoolPrices(tokens, prices, pool, chain = "eth") {
    if (pool.w0 != null) throw new Error("return getValuePrices(tokens, prices, pool);")
    if (pool.poolTokens != null) throw new Error("return getBalancerPrices(tokens, prices, pool);")
    if (pool.token0 != null) return getUniPrices(tokens, prices, pool);
    if (pool.virtualPrice != null) throw new Error("return getCurvePrices(prices, pool);")
    if (pool.token != null) return getWrapPrices(tokens, prices, pool);
    return getErc20Prices(prices, pool, chain);
}


function getParameterCaseInsensitive(object, key) {
    return object[Object.keys(object)
        .find(k => k.toLowerCase() === key.toLowerCase())
    ];
}


function getErc20Prices(prices, pool, chain = "eth") {
    var price = getParameterCaseInsensitive(prices, pool.address)?.usd;
    var tvl = pool.totalSupply * price / 10 ** pool.decimals;
    var staked_tvl = pool.staked * price;
    let poolUrl;
    switch (chain) {
        case "eth":
            poolUrl = `https://etherscan.io/token/${pool.address}`;
            break;
        case "bsc":
            poolUrl = `https://bscscan.com/token/${pool.address}`;
            break;
        case "heco":
            poolUrl = `https://hecoinfo.com//token/${pool.address}`;
            break;
        case "matic":
            poolUrl = `https://explorer-mainnet.maticvigil.com/address/${pool.address}`;
            break;
        case "avax":
            poolUrl = `https://cchain.explorer.avax.network/address/${pool.address}`;
            break;
        case "fantom":
            poolUrl = `https://ftmscan.com/token/${pool.address}`;
            break;
        case "fuse":
            poolUrl = `https://explorer.fuse.io/address/${pool.address}`;
            break;
    }
    const name = `<a href='${poolUrl}' target='_blank'>${pool.symbol}</a>`;
    return {
        staked_tvl: staked_tvl,
        price: price,
        stakeTokenTicker: pool.symbol,
        /*        print_price() {
                    return `${name} Price: $${displayPrice(price)} Market Cap: $${formatMoney(tvl)}` +
                        `Staked: ${pool.staked.toFixed(4)} ${pool.symbol} ($${formatMoney(staked_tvl)})`;
                }, */
        tvl: tvl,
        staked: pool.staked.toFixed(4),
        symbol: pool.symbol,
        /*        pair_links() {
                    return {
                        pair_link: name,
                        add_liquidity_link: "",
                        remove_liquidity_link: "",
                        swap_link: "",
                        price0: "",
                        price1: "",
                        total_staked: `${pool.staked.toFixed(4)}`,
                        total_staked_dollars: `$${formatMoney(staked_tvl)}`,
                        tvl: `$${formatMoney(tvl)}`
                    }
                }, */
        print_contained_price() {
        }
    }
}




function getWrapPrices(tokens, prices, pool) {
    const wrappedToken = pool.token;
    if (wrappedToken.token0 != null) { //Uniswap
        const uniPrices = getUniPrices(tokens, prices, wrappedToken);
        const poolUrl = pool.is1inch ? "https://1inch.exchange/#/dao/pools" :
            pool.symbol.includes("SLP") ? `http://analytics.sushi.com/pairs/${wrappedToken.address}` :
                (pool.symbol.includes("Cake") || pool.symbol.includes("Pancake")) ? `http://pancakeswap.info/pair/${wrappedToken.address}`
                    : `http://uniswap.info/pair/${wrappedToken.address}`;
        const name = `Wrapped <a href='${poolUrl}' target='_blank'>${uniPrices.stakeTokenTicker}</a>`;
        const price = (pool.balance / 10 ** wrappedToken.decimals) * uniPrices.price / (pool.totalSupply / 10 ** pool.decimals);
        const tvl = pool.balance / 10 ** wrappedToken.decimals * price;
        const staked_tvl = pool.staked * price;

        prices[pool.address] = { usd: price };
        return {
            name: name,
            tvl: tvl,
            staked_tvl: staked_tvl,
            symbol: pool.symbol,
            price: price,
            stakeTokenTicker: pool.symbol,
            staked: pool.staked.toFixed(4),
            /*print_price() {
                _print(`${name} Price: $${formatMoney(price)} TVL: $${formatMoney(tvl)}`);
                _print(`Staked: ${)} ${pool.symbol} ($${formatMoney(staked_tvl)})`);
            },*/
            print_contained_price(_) {
            }
        }
    }
    else {
        let tokenPrice = 0;
        if (wrappedToken.token != null) { //e.g. stakedao crv token vault
            const pp = getPoolPrices(tokens, prices, wrappedToken.token)
            tokenPrice = pp.price;
        }
        else {
            tokenPrice = getParameterCaseInsensitive(prices, wrappedToken.address)?.usd;
        }
        const price = (pool.balance / 10 ** wrappedToken.decimals) * tokenPrice / (pool.totalSupply / 10 ** pool.decimals);
        const tvl = pool.balance / 10 ** wrappedToken.decimals * price;
        const staked_tvl = pool.staked * price;
        prices[pool.address] = { usd: price };
        return {
            name: pool.symbol,
            tvl: tvl,
            staked_tvl: staked_tvl,
            price: price,
            stakeTokenTicker: pool.symbol,
            symbol: pool.symbol,
            staked: pool.staked.toFixed(4),
            /* print_price() {
                _print(`${pool.symbol} Price: $${formatMoney(price)} TVL: $${formatMoney(tvl)}`);
                _print(`Staked: ${pool.staked.toFixed(4)} ${pool.symbol} ($${formatMoney(staked_tvl)})`);
            }, */
            print_contained_price(_) {
            }
        }
    }
}


async function getToken(app, tokenAddress, stakingAddress, myAddress) {
    if (tokenAddress == "0x0000000000000000000000000000000000000000") {
        return getErc20(app, null, tokenAddress, stakingAddress, myAddress)
    }
    /* const type = window.localStorage.getItem(tokenAddress);
    //getTokenWeights
    if (type) return getStoredToken(app, tokenAddress, stakingAddress, type); */

    try {
        const pool = new Contract(tokenAddress, UNI_ABI);
        const _token0 = await app.ethcallProvider.all([pool.token0()]);
        const uniPool = await getUniPool(app, pool, tokenAddress, stakingAddress, myAddress);
        window.localStorage.setItem(tokenAddress, "uniswap");
        return uniPool;
    }
    catch (err) {
    }/*
    try {
        const bal = new ethcall.Contract(tokenAddress, BALANCER_POOL_ABI);
        const [tokens] = await app.ethcallProvider.all([bal.getFinalTokens()]);
        const balPool = await getBalancerPool(app, bal, tokenAddress, stakingAddress, tokens);
        window.localStorage.setItem(tokenAddress, "balancer");
        return balPool;
    }
    catch (err) {
    }
    try {
        const sbal = new ethcall.Contract(tokenAddress, BPOOL_TOKEN_ABI);
        const [bPool] = await app.ethcallProvider.all([sbal.bPool(), sbal.totalSupply()]);
        const bal = new ethcall.Contract(bPool, BPOOL_ABI);
        const [tokens] = await app.ethcallProvider.all([bal.getCurrentTokens()]);
        const balPool = await getBalancerPool(app, bal, tokenAddress, stakingAddress, tokens, sbal);
        window.localStorage.setItem(tokenAddress, "balancerSmart");
        return balPool;
    }
    catch (err) {
    }*/
    try {
        const jar = new Contract(tokenAddress, JAR_ABI);
        const _token = await app.ethcallProvider.all([jar.token()]);
        const res = await getJar(app, jar, tokenAddress, stakingAddress, myAddress);
        window.localStorage.setItem(tokenAddress, "jar");
        return res;
    }
    catch (err) {
    }/*
    try {
        const cToken = new ethcall.Contract(tokenAddress, CTOKEN_ABI);
        const _token = await app.ethcallProvider.all([cToken.underlying()]);
        const res = await getCToken(app, cToken, tokenAddress, stakingAddress);
        window.localStorage.setItem(tokenAddress, "cToken");
        return res;
    }
    catch (err) {
    }
    try {
        const vault = new ethcall.Contract(tokenAddress, HARVEST_VAULT_ABI);
        const _token = await app.ethcallProvider.all([vault.underlying()]);
        const res = await getVault(app, vault, tokenAddress, stakingAddress);
        window.localStorage.setItem(tokenAddress, "vault");
        return res;
    }
    catch (err) {
    }
    try {
        const saddle = new ethcall.Contract(tokenAddress, SADDLE_LP_TOKEN_ABI);
        const [swap] = await app.ethcallProvider.all([saddle.swap()]);
        const res = await getSaddleToken(app, saddle, tokenAddress, stakingAddress, swap);
        window.localStorage.setItem(tokenAddress, "saddle");
        return res;
    }
    catch (err) {
    } */
    try {
        const crv = new Contract(tokenAddress, CURVE_ABI);
        if (tokenAddress.toLowerCase() == "0x88E11412BB21d137C217fd8b73982Dc0ED3665d7".toLowerCase()) {
            const minter = "0x3333333ACdEdBbC9Ad7bda0876e60714195681c5";
            const res = await getCurveToken(app, crv, tokenAddress, stakingAddress, minter, myAddress);
            window.localStorage.setItem(tokenAddress, "curve");
            return res;
        }
        const [minter] = await app.ethcallProvider.all([crv.minter()]);
        const res = await getCurveToken(app, crv, tokenAddress, stakingAddress, minter, myAddress);
        window.localStorage.setItem(tokenAddress, "curve");
        return res;
    }
    catch (err) {
    }/*
    try {
        const stable = new ethcall.Contract(tokenAddress, STABLESWAP_ABI);
        const _coin0 = await app.ethcallProvider.all([stable.coins(0)]);
        window.localStorage.setItem(tokenAddress, "stableswap");
        return await getStableswapToken(app, stable, tokenAddress, stakingAddress);
    }
    catch (err) {
    } */
    try {
        const erc20 = new Contract(tokenAddress, ERC20_ABI);
        const _name = await app.ethcallProvider.all([erc20.name()]);
        const erc20tok = await getErc20(app, erc20, tokenAddress, stakingAddress, myAddress);
        window.localStorage.setItem(tokenAddress, "erc20");
        return erc20tok;
    }
    /* catch (err) {
    }
    try {
        const dsToken = new ethcall.Contract(tokenAddress, DSTOKEN_ABI);
        const res = await getDSToken(app, dsToken, tokenAddress, stakingAddress);
        window.localStorage.setItem(tokenAddress, "dsToken");
        return res;
    } */
    catch (err) {
        console.error(err)
        console.log(`Couldn't match ${tokenAddress} to any known token type.`);
    }
}


async function getErc20(app, token, address, stakingAddress, myAddress) {
    if (address == "0x0000000000000000000000000000000000000000") {
        return {
            address,
            name: "Ethereum",
            symbol: "ETH",
            totalSupply: 1e8,
            decimals: 18,
            staked: await app.provider.getBalance(stakingAddress) / 1e18,
            unstaked: await app.provider.getBalance(myAddress) / 1e18,
            contract: null,
            tokens: [address]
        }
    }
    const calls = [token.decimals(), token.balanceOf(stakingAddress), token.balanceOf(myAddress),
    token.name(), token.symbol(), token.totalSupply()];
    const [decimals, staked, unstaked, name, symbol, totalSupply] = await app.ethcallProvider.all(calls);
    return {
        address,
        name,
        symbol,
        totalSupply,
        decimals: decimals,
        staked: staked / 10 ** decimals,
        unstaked: unstaked / 10 ** decimals,
        contract: token,
        tokens: [address]
    };
}


async function getCurveToken(app, curve, address, stakingAddress, minterAddress, myAddress) {
    const minter = new Contract(minterAddress, MINTER_ABI)
    const [virtualPrice, coin0] = await app.ethcallProvider.all([minter.get_virtual_price(), minter.coins(0)]);
    const token = await getToken(app, coin0, address, myAddress);
    const calls = [curve.decimals(), curve.balanceOf(stakingAddress), curve.balanceOf(myAddress),
    curve.name(), curve.symbol(), curve.totalSupply()];
    const [decimals, staked, unstaked, name, symbol, totalSupply] = await app.ethcallProvider.all(calls);
    return {
        address,
        name,
        symbol,
        totalSupply,
        decimals: decimals,
        staked: staked / 10 ** decimals,
        unstaked: unstaked / 10 ** decimals,
        contract: curve,
        tokens: [address, coin0],
        token,
        virtualPrice: virtualPrice / 1e18
    };
}


async function getJar(app, jar, address, stakingAddress, myAddress) {
    const calls = [jar.decimals(), jar.token(), jar.name(), jar.symbol(), jar.totalSupply(),
    jar.balanceOf(stakingAddress), jar.balanceOf(myAddress), jar.balance()];
    const [decimals, token_, name, symbol, totalSupply, staked, unstaked, balance] =
        await app.ethcallProvider.all(calls);
    const token = await getToken(app, token_, address, myAddress);
    return {
        address,
        name,
        symbol,
        totalSupply,
        decimals: decimals,
        staked: staked / 10 ** decimals,
        unstaked: unstaked / 10 ** decimals,
        token: token,
        balance: balance,
        contract: jar,
        tokens: [address].concat(token.tokens)
    }
}



function getUniPrices(tokens, prices, pool) {
    var t0 = getParameterCaseInsensitive(tokens, pool.token0);
    var p0 = getParameterCaseInsensitive(prices, pool.token0)?.usd;
    var t1 = getParameterCaseInsensitive(tokens, pool.token1);
    var p1 = getParameterCaseInsensitive(prices, pool.token1)?.usd;
    if (p0 == null && p1 == null) {
        console.log(`Missing prices for tokens ${pool.token0} and ${pool.token1}.`);
        return undefined;
    }
    if (t0?.decimals == null) {
        console.log(`Missing information for token ${pool.token0}.`);
        return undefined;
    }
    if (t1?.decimals == null) {
        console.log(`Missing information for token ${pool.token1}.`);
        return undefined;
    }
    var q0 = pool.q0 / 10 ** t0.decimals;
    var q1 = pool.q1 / 10 ** t1.decimals;
    if (p0 == null) {
        p0 = q1 * p1 / q0;
        prices[pool.token0] = { usd: p0 };
    }
    if (p1 == null) {
        p1 = q0 * p0 / q1;
        prices[pool.token1] = { usd: p1 };
    }
    var tvl = q0 * p0 + q1 * p1;
    var price = tvl / pool.totalSupply;
    prices[pool.address] = { usd: price };
    var staked_tvl = pool.staked * price;
    let stakeTokenTicker = `[${t0.symbol}]-[${t1.symbol}]`;
    if (pool.is1inch) stakeTokenTicker += " 1INCH LP";
    else if (pool.symbol.includes("LSLP")) stakeTokenTicker += " LSLP";
    else if (pool.symbol.includes("BLP")) stakeTokenTicker += " BLP";
    else if (pool.symbol.includes("SLP")) stakeTokenTicker += " SLP";
    else if (pool.symbol.includes("Cake")) stakeTokenTicker += " Cake LP";
    else if (pool.name.includes("Value LP")) stakeTokenTicker += " Value LP";
    else if (pool.symbol.includes("PGL")) stakeTokenTicker += " PGL";
    else if (pool.symbol.includes("CS-LP")) stakeTokenTicker += " CSS LP";
    else if (pool.symbol.includes("DFYN")) stakeTokenTicker += " DFYN LP";
    else if (pool.symbol.includes("SPIRIT")) stakeTokenTicker += " SPIRIT LP";
    else if (pool.symbol.includes("spLP")) stakeTokenTicker += " SPOOKY LP";
    else if (pool.symbol.includes("Lv1")) stakeTokenTicker += " STEAK LP";
    else if (pool.symbol.includes("PLP")) stakeTokenTicker += " Pure Swap LP";
    else if (pool.symbol.includes("Field-LP")) stakeTokenTicker += " Yield Fields LP";
    else if (pool.symbol.includes("UPT")) stakeTokenTicker += " Unic Swap LP";
    else if (pool.symbol.includes("ELP")) stakeTokenTicker += " ELK LP";
    else stakeTokenTicker += " Uni LP";
    return {
        t0: t0,
        p0: p0,
        q0: q0,
        t1: t1,
        p1: p1,
        q1: q1,
        price: price,
        tvl: tvl,
        staked_tvl: staked_tvl,
        stakeTokenTicker: stakeTokenTicker,
        print_contained_price(userStaked) {
            var userPct = userStaked / pool.totalSupply;
            var q0user = userPct * q0;
            var q1user = userPct * q1;
            return `Your LP tokens comprise of ${q0user.toFixed(4)} ${t0.symbol} + ${q1user.toFixed(4)} ${t1.symbol}`;
        }
    }
}


async function getUniPool(app, pool, poolAddress, stakingAddress, myAddress) {
    const calls = [
        pool.decimals(), pool.token0(), pool.token1(), pool.symbol(), pool.name(),
        pool.totalSupply(), pool.balanceOf(stakingAddress), pool.balanceOf(myAddress)
    ];
    const [decimals, token0, token1, symbol, name, totalSupply, staked, unstaked]
        = await app.ethcallProvider.all(calls);
    let q0, q1, is1inch;
    try {
        const [reserves] = await app.ethcallProvider.all([pool.getReserves()]);
        q0 = reserves._reserve0;
        q1 = reserves._reserve1;
        is1inch = false;
    }
    catch { //for 1inch
        if (token0 == "0x0000000000000000000000000000000000000000") {
            q0 = await app.provider.getBalance(poolAddress);
        }
        else {
            const c0 = new ethers.Contract(token0, ERC20_ABI, app.provider);
            q0 = await c0.balanceOf(poolAddress);
        }
        if (token1 == "0x0000000000000000000000000000000000000000") {
            q1 = await app.provider.getBalance(poolAddress);
        }
        else {
            const c1 = new ethers.Contract(token1, ERC20_ABI, app.provider);
            q1 = await c1.balanceOf(poolAddress);
        }
        is1inch = true;
    }
    return {
        symbol,
        name,
        address: poolAddress,
        token0: token0,
        q0,
        token1: token1,
        q1,
        totalSupply: totalSupply / 10 ** decimals,
        stakingAddress: stakingAddress,
        staked: staked / 10 ** decimals,
        decimals: decimals,
        unstaked: unstaked / 10 ** decimals,
        contract: pool,
        tokens: [token0, token1],
        is1inch
    };
}
