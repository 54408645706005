import Onboard from 'bnc-onboard';
import { useEffect, useState } from 'react';
import Web3 from 'web3';
import './App.css';
import { StakeDAO } from './modules/yf/stakedao';
import { AppContext } from './shared/context/AppContext';


function App() {
  // set a variable to store instantiated web3
  // let web3

  // head to blocknative.com to create a key
  const BLOCKNATIVE_KEY = 'd2e3413a-0c46-4c5c-b3e0-9ede290ed4fd'

  // the network id that your dapp runs on
  const NETWORK_ID = 1

  const [web3, setWeb3] = useState(null)
  const [address, setAddress] = useState(null)
  const [onboard, setOnboard] = useState(null)
  const [wallet, setWallet] = useState({})
  const [isHW, setIsHW] = useState(false)


  useEffect(() => {
    // initialize onboard
    const RPC_URL = "https://mainnet.infura.io/v3/7423d77cd6c1479ea4b429cfb1f46018"
    const APP_NAME = "DeFi Dashboard"
    const INFURA_KEY = "7423d77cd6c1479ea4b429cfb1f46018"
    const APP_URL = "https://defi.exan.tech"
    const CONTACT_EMAIL = "hello@exan.tech"

    const webusb = require('@ledgerhq/hw-transport-webusb').default;

    const wallets = [
      { walletName: "coinbase" },
      { walletName: "trust", preferred: true, rpcUrl: RPC_URL },
      { walletName: "metamask", preferred: true },
      {
        walletName: 'trezor',
        appUrl: APP_URL,
        email: CONTACT_EMAIL,
        rpcUrl: RPC_URL
      },
      {
        walletName: 'ledger',
        rpcUrl: RPC_URL,
        LedgerTransport: webusb,
        preferred: true
      },
      {
        walletName: 'cobovault',
        rpcUrl: RPC_URL,
        appName: APP_NAME,
      },
      {
        walletName: "walletConnect",
        preferred: true,
        infuraKey: INFURA_KEY
      },
      { walletName: "gnosis", preferred: true },
    ]

    const _onboard = Onboard({
      dappId: BLOCKNATIVE_KEY,
      networkId: NETWORK_ID,
      walletSelect: {
        wallets: wallets,
      },
      walletCheck: [
        { checkName: 'derivationPath' },
        { checkName: 'connect' },
        { checkName: 'accounts' },
        { checkName: 'network' },
      ],
      subscriptions: {
        wallet: wallet => {
          if (wallet.provider) {
            const _web3 = new Web3(wallet.provider)
            console.log(`${wallet.name} connected!`)

            setWeb3(_web3)
            setWallet(wallet)
            setIsHW(wallet.type === "hardware")

          } else {
            setWeb3(null)
            setWallet({})
            setIsHW(false)
          }
        },
        address: setAddress
      }
    })
    setOnboard(_onboard)
  }, []);

  const appState = {
    wallet,
    address
  }

  return (
    <AppContext.Provider value={appState}>
      <div className="App-header">
        <div>
          {address ? <span>Account: {address}</span> : null}
          {!address ? <a href="" onClick={async (e) => { e.preventDefault(); await onboard.walletSelect(); await onboard.walletCheck() }}>Connect wallet</a> : null}
          {isHW ? <a href="" onClick={async (e) => { e.preventDefault(); await onboard.accountSelect() }}>Switch Account</a> : null}
          {address ? <a href="" onClick={async (e) => { e.preventDefault(); await onboard.walletReset(); }}>Reset</a> : null}
        </div>
      </div>

      <div>
        {!address ? <div className="App-warn">Connect wallet first</div> : null}
        {address ? <StakeDAO /> : null}
      </div>
    </AppContext.Provider>
  );
}

export default App;
